@tailwind base;
@tailwind components;
@tailwind utilities;

body .tf-v1-widget-fullscreen .tf-v1-widget-close {
  color: rgb(89, 89, 89) !important;
}

:root {
  --notion-font: 'Poppins', ui-sans-serif, system-ui, apple-system,
    BlinkMacSystemFont, 'Segoe UI', Helvetica, 'Apple Color Emoji', Arial,
    sans-serif, 'Segoe UI Emoji', 'Segoe UI Symbol';
  --fg-color: rgb(55, 53, 47);
  --fg-color-0: rgba(55, 53, 47, 0.09);
  --fg-color-1: rgba(55, 53, 47, 0.16);
  --fg-color-2: rgba(55, 53, 47, 0.4);
  --fg-color-3: rgba(55, 53, 47, 0.6);
  --fg-color-4: #000;
  --fg-color-5: rgba(55, 53, 47, 0.024);
  --fg-color-6: rgba(55, 53, 47, 0.8);
  --fg-color-icon: var(--fg-color);

  --bg-color: #fff;
  --bg-color-0: rgba(135, 131, 120, 0.15);
  --bg-color-1: rgb(247, 246, 243);
  --bg-color-2: rgba(135, 131, 120, 0.15);

  --select-color-0: rgb(46, 170, 220);
  --select-color-1: rgba(45, 170, 219, 0.3);
  --select-color-2: #d9eff8;

  --notion-red: rgb(224, 62, 62);
  --notion-pink: rgb(173, 26, 114);
  --notion-blue: rgb(11, 110, 153);
  --notion-purple: rgb(105, 64, 165);
  --notion-teal: rgb(77, 100, 97);
  --notion-yellow: rgb(223, 171, 1);
  --notion-orange: rgb(217, 115, 13);
  --notion-brown: rgb(100, 71, 58);
  --notion-gray: rgb(155, 154, 151);

  --notion-red_background: rgb(251, 228, 228);
  --notion-pink_background: rgb(244, 223, 235);
  --notion-blue_background: rgb(221, 235, 241);
  --notion-purple_background: rgb(234, 228, 242);
  --notion-teal_background: rgb(221, 237, 234);
  --notion-yellow_background: rgb(251, 243, 219);
  --notion-orange_background: rgb(250, 235, 221);
  --notion-brown_background: rgb(233, 229, 227);
  --notion-gray_background: rgb(235, 236, 237);

  --notion-red_background_co: rgba(251, 228, 228, 0.3);
  --notion-pink_background_co: rgba(244, 223, 235, 0.3);
  --notion-blue_background_co: rgba(221, 235, 241, 0.3);
  --notion-purple_background_co: rgba(234, 228, 242, 0.3);
  --notion-teal_background_co: rgba(221, 237, 234, 0.3);
  --notion-yellow_background_co: rgba(251, 243, 219, 0.3);
  --notion-orange_background_co: rgba(250, 235, 221, 0.3);
  --notion-brown_background_co: rgba(233, 229, 227, 0.3);
  --notion-gray_background_co: rgba(235, 236, 237, 0.3);

  --notion-item-blue: rgba(0, 120, 223, 0.2);
  --notion-item-orange: rgba(245, 93, 0, 0.2);
  --notion-item-green: rgba(0, 135, 107, 0.2);
  --notion-item-pink: rgba(221, 0, 129, 0.2);
  --notion-item-brown: rgba(140, 46, 0, 0.2);
  --notion-item-red: rgba(255, 0, 26, 0.2);
  --notion-item-yellow: rgba(233, 168, 0, 0.2);
  --notion-item-default: rgba(206, 205, 202, 0.5);
  --notion-item-purple: rgba(103, 36, 222, 0.2);
  --notion-item-gray: rgba(155, 154, 151, 0.4);

  --notion-max-width: '80%';
  --notion-header-height: 45px;
}

.notion {
  font-size: 13px;
  line-height: 20.8.px;
  color: var(--fg-color);
  caret-color: var(--fg-color);
  font-family: var(--notion-font);
  @apply pl-5 pr-20;
}

.rdp-vhidden {
  @apply hidden;
}

@layer base {
  h1 {
    @apply text-[36px] leading-[45px] font-semibold md:text-[48px] md:leading-[58px];
  }

  h2 {
    @apply text-[28px] leading-[35px] font-semibold md:text-[32px] md:leading-[38.4px];
  }

  h3 {
    @apply text-[20px] leading-[24px] font-semibold md:text-[26px] md:leading-[31.2px];
  }

  h4 {
    @apply text-[16px] leading-[22.4px] font-semibold md:text-[20px] md:leading-[28px];
  }

  h5 {
    @apply text-[14px] leading-[21px] font-semibold uppercase;
  }

  h6 {
    @apply text-[14px] leading-[21px] font-semibold;
  }

  small {
    @apply text-[9px] leading-[12.6px] font-semibold;
  }

  body {
    @apply text-[16px] leading-[22.4px] text-body;
    font-family: 'Poppins', ui-sans-serif, system-ui, apple-system,
      BlinkMacSystemFont, 'Segoe UI', Helvetica, 'Apple Color Emoji', Arial,
      sans-serif, 'Segoe UI Emoji', 'Segoe UI Symbol';
  }

  section {
    @apply py-[50px] md:py-[75px];
  }
}

@layer utilities {
  .scroll-snap-x {
    scroll-snap-type: x mandatory;
  }

  .snap-start {
    scroll-snap-align: start;
  }

  .snap-smooth {
    scroll-behavior: smooth;
  }

  .snap-px-4 {
    scroll-padding-left: 1rem;
    scroll-padding-right: 1rem;
  }

  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
  }
}

* {
  font-variant-ligatures: none;
}

html {
  scroll-padding-top: 10rem;
}

model-viewer::part(default-progress-mask) {
  display: none;
}

model-viewer::part(default-progress-bar) {
  display: none;
}
